import React, { useEffect, useState } from "react";
import "./home.css";
import axios from "axios";
import { useTranslation } from "react-i18next";

export default function Home() {
  const [anuncio, setAnuncio] = useState("");
  const search = 1;
  const { t } = useTranslation("global");

  useEffect(() => {
    const fetchAnuncio = async () => {
      const res = await axios.get("/api/anuncios/" + search);
      const anuncioData = res.data;
      if (anuncioData !== null) {
        setAnuncio(anuncioData);
      } else {
        setAnuncio("");
      }
    };
    fetchAnuncio();
  }, [search]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="position-relative bloq_a">
            <img src={anuncio !== null && anuncio !== "" ? anuncio.imagen : "#"} alt="Imagen" className="img-fluid" /> 
            <div className="text_p">
              <h1 className="text-white fw-bold">{t("text_anuncio.title")} </h1>
              <p className="text-white mt-3">{t("text_anuncio.desc")} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
